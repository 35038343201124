const { buildQuerySummary } = require('@coveo/headless');
const {
    buildProductListing,
    buildPager,
    buildSort,
    buildRelevanceSortCriterion,
    buildFieldsSortCriterion,
    buildResultsPerPage,
    buildInteractiveResult
} = require('@coveo/headless/product-listing');

import { getProductListUrl } from '../utils/searchUtils';
import { productListingEngine as engine } from '../Engine';

var productListingController, pagerController;

const getProductListingUrl = () => {
    let url = 'https://www.sunbeltrentals.com';
    if (window.location.pathname.includes('equipment-rental/')) {
        url += getProductListUrl();
    } else {
        url =
            'https://www.sunbeltrentals.com/equipment-rental/aerial-work-platforms-scaffolding-and-ladders/electric-scissorlifts/';
    }
    sessionStorage.setItem("listingReqUrl", url)
    return url;
};

const urlParams = new URLSearchParams(window.location.search);
const currentPage = Math.max(Number(urlParams.get("page")) || 1, 1);

const productListingProps = {
    options: {
        url: getProductListingUrl(),
        additionalFields: [
            'categoryl2',
            'categoryl3',
            'productcatsortindex',
            'ec_primary_image',
            'ec_skus',
            'ec_product_url',
            'ec_show_onlinecatalog',
            'ec_disable_addtocartoption',
            'source',
            'ec_category',
            'ec_category_id',
            'ec_pc_inventory',
            'ec_meta_title',
            'ec_meta_description',
            'ec_specialtytypes',
            'ec_specialtytypecode',
            'ec_analysisgroupcode',
            'ec_specifications',
            'ec_isgreenleaf',
            'ec_greenleafdata'
        ]
    }
};

const pagerProps = {
    initialState: {
        page: currentPage  // Set currentPage as initial state
    },
    options: {
        numberOfPages: 5
    }
};

const resultsPagerProps = {
    initialState: {
        numberOfResults: 24
    }
};

productListingController = buildProductListing(engine, productListingProps);

pagerController = buildPager(engine, pagerProps);

buildResultsPerPage(engine, resultsPagerProps);
var relevanceSortCriterion = buildRelevanceSortCriterion();
var nameSortAsc = buildFieldsSortCriterion([{ name: 'ec_name', direction: 'asc' }]);
var nameSortDesc = buildFieldsSortCriterion([{ name: 'ec_name', direction: 'desc' }]);

let initialSortCriterion = ''; 
const urlQueryParams = urlParams.get("sort");
if(urlQueryParams === 'sortAsc') {
    initialSortCriterion = nameSortAsc
} else if(urlQueryParams === 'sortDesc') {
    initialSortCriterion = nameSortDesc
} else {
    initialSortCriterion = relevanceSortCriterion
}

var sortController = buildSort(engine, {
    initialState: {
        criterion: initialSortCriterion
    }
});

var querySummaryController = buildQuerySummary(engine);
var interactiveResultController = buildInteractiveResult(engine, productListingProps);
var categoryLevel = document.querySelector("meta[name='categoryLevel']")?.content;
//listing calls removed from other pages except plp
if (categoryLevel) {
    // productListingController.refresh();
}

export {
    productListingController,
    sortController,
    relevanceSortCriterion,
    nameSortAsc,
    nameSortDesc,
    querySummaryController,
    pagerController,
    interactiveResultController
};
